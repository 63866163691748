import React, { useState, useEffect } from 'react';
import { Button, EmptyLayout, Paragraph as P } from '@periodica/ui-kit';
import { Link } from 'gatsby';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import Layout from '@components/Layouts/Layout';
import { OneColumn } from '@components/Layouts/OneColumn';
import SEO from '../../components/Layouts/SEO';

export function Head() {
  return (
    <>
      <SEO
        title="Заказ оплачен!"
        description="Подтверждение на почте. Мы вам сообщим, когда заказ будет готов и передан в службу
  доставки."
      />
      <YandexTableau />
    </>
  );
}

function PaymentPendingPage() {
  const [type, setType] = useState(null);
  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const result = params.get('type');
    if (result) {
      setType(result);
    }
  }, []);

  return (
    <Layout>
      <OneColumn>
        <EmptyLayout
          title="Заказ оплачен!"
          description={
            <P size="inherit">
              {type === 'design'
                ? 'Ваш заказ успешно оформлен и принят в работу! Подтверждение на почте. Мы вам сообщим, когда дизайн будет готов.'
                : 'Ваш заказ успешно оформлен и принят в работу! Подтверждение на почте. Мы вам сообщим, когда заказ будет готов и передан в службу доставки.'}
            </P>
          }
        >
          <Button size="xLarge" variant="secondary" asChild>
            <Link to="/" className="order-success__button">
              Хорошо
            </Link>
          </Button>
        </EmptyLayout>
      </OneColumn>
    </Layout>
  );
}

export default PaymentPendingPage;
